import { Component, inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { NotificationHelperService } from '@offconon/shared/utils/services';

@Component({
  selector: 'offconon-notification-type-add-employee-email-address',
  templateUrl: './notification-type-add-employee-email-address.component.html',
  imports: [TranslateModule],
})
export class NotificationTypeAddEmployeeEmailAddressComponent {
  private router = inject(Router);

  @Input() notification_data: any;

  private notificactionHelperService = inject(NotificationHelperService);

  openPost() {
    this.notificactionHelperService.closeNotificationPanel(false);
    this.router.navigate(['/emails']);
  }
}
