import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventBusService } from 'libs/shared/features/layout/layout/src/lib/layout/data/services/event-bus-service.service';
import { TruncatePipe } from 'libs/shared/utils/pipes/src/lib/truncate.pipe';
import { MenuItem } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { MenuModule } from 'primeng/menu';
import { PanelModule } from 'primeng/panel';
import { TooltipModule } from 'primeng/tooltip';

import { NotificationsService } from '@offconon/core-api';
import { MonogramComponent } from '@offconon/shared/ui/monogram';
import { TimeagoComponent } from '@offconon/shared/ui/timeago';
import { EnvironmentService } from '@offconon/shared/utils/services';
import { NotificationHelperService } from '@offconon/shared/utils/services';

import { NotificationTypeActivityStreamReactionComponent } from '../types/notification-type-activity-stream-reaction/notification-type-activity-stream-reaction.component';
import { NotificationTypeAddEmployeeEmailAddressComponent } from '../types/notification-type-add-employee-email-address/notification-type-add-employee-email-address.component';
import { NotificationTypeNewFollowerComponent } from '../types/notification-type-new-follower/notification-type-new-follower.component';
import { NotificationTypeSalesTeamComponent } from '../types/notification-type-sales-team/notification-type-sales-team.component';

@Component({
  selector: 'offconon-notification-common-panel',
  imports: [
    CommonModule,
    PanelModule,
    TranslateModule,
    TruncatePipe,
    AvatarModule,
    MonogramComponent,
    MatIcon,
    TooltipModule,
    TimeagoComponent,
    MenuModule,
    NotificationTypeActivityStreamReactionComponent,
    NotificationTypeAddEmployeeEmailAddressComponent,
    NotificationTypeNewFollowerComponent,
    NotificationTypeSalesTeamComponent,
  ],
  templateUrl: './notification-common-panel.component.html',
  styles: [
    `
      .p-menu-list {
        max-height: calc(100vh - 300px);
        overflow: auto;
      }
    `,
  ],
})
export class NotificationCommonPanelComponent implements OnInit {
  private environmentService = inject(EnvironmentService);
  @Input() system_data: any;
  @Input() collapsedStatus = true;
  @Input() truncate_number = 35;
  @Input() parameters: any;
  @Input() isMobileView: any;
  @Input() platform = 'core';

  private eventBus = inject(EventBusService);
  private destroyRef = inject(DestroyRef);
  private notificationsService = inject(NotificationsService);
  private router = inject(Router);
  private translateService = inject(TranslateService);
  public notificactionHelperService = inject(NotificationHelperService);

  items: MenuItem[];

  full_name: any;
  avatar_path: any;
  username: any;
  user_id: any;
  subject: any;

  is_subscription = false;
  is_verified = false;
  subscription_icon: any;
  subscription_color: any;
  subscription_name: any;
  profile_link = '/';
  monogram_class = 'ml-auto mt-2';

  business_data: any;
  user_data: any;
  coworker_data: any;
  app_data: any;
  rpa_data: any;

  ngOnInit() {
    if (this.system_data?.sender_type === 'user' && this.system_data.sender_user) {
      this.user_data = this.system_data.sender_user;
    }

    if (this.system_data.sender_type === 'business' && this.system_data.sender_business) {
      this.business_data = this.system_data.sender_business;
    }

    if (this.system_data.sender_type === 'b_co_worker' && this.system_data.sender_b_co_worker) {
      this.coworker_data = this.system_data.sender_b_co_worker;
    }

    if (this.user_data) {
      this.full_name = this.user_data?.full_name;
      this.avatar_path = this.user_data?.avatar_path;
      this.username = this.user_data?.username;
      this.user_id = this.user_data?.user?.id;
      this.is_subscription = this.user_data?.is_subscription;
      this.is_verified = this.user_data?.is_verified;
      this.subscription_icon = this.user_data?.subscription_icon;
      this.subscription_color = this.user_data?.subscription_icon_color;
      this.subscription_name = this.user_data?.subscription_type;
      this.profile_link = '/profile/' + this.user_data?.username;
    } else if (this.business_data) {
      this.avatar_path = this.business_data.business_avatar_path;
      this.full_name = this.business_data.business_name;
      this.is_subscription = this.business_data.is_subscription;
      this.is_verified = this.business_data.business_is_verified;
      this.subscription_name = this.business_data.business_subscription_type;
      this.subscription_icon = this.business_data.subscription_icon;
      this.subscription_color = this.business_data.subscription_icon_color;
      this.username = this.business_data.business_mention_name;
      this.profile_link = '/business-profile/' + this.business_data.business_mention_name;
    } else if (this.coworker_data) {
      this.avatar_path = this.coworker_data.avatar_path;
      this.username = this.coworker_data.username;
      this.full_name = this.coworker_data.full_name;
      this.profile_link = '/co-workers/profile/' + this.coworker_data.co_worker_id;
    } else {
      this.avatar_path = '/assets/images/offconon_pure_logo_profile.png';
      this.full_name = this.translateService.instant('MR AI automation');
    }

    this.renderMenu(this.system_data);
  }

  renderMenu(data: any) {
    let link = '';
    let icon = 'check_box';
    let readText = 'Mark as read';
    switch (data.notification_type_title) {
      case 'new_follower':
        link = this.profile_link;
        break;
      case 'activity_stream_reaction':
        link = '/activity-stream/show/' + data.notification_profile_data.activity_stream_common_id;
        break;

      default:
        link = this.profile_link;
        break;
    }
    if (data.read) {
      icon = 'check_box_outline_blank';
      readText = 'Mark as unread';
    }
    this.items = [
      {
        items: [
          {
            label: this.translateService.instant(readText),
            icon: icon,
            command: () => {
              this.markRead(!data.read);
            },
          },
          {
            label: this.translateService.instant('Open'),
            icon: 'folder_open',
            command: () => {
              this.openSubPage(link);
            },
          },
          {
            label: this.translateService.instant('Sender profile'),
            icon: 'person',
            command: () => {
              this.openEntityProfile();
            },
          },
          {
            label: this.translateService.instant('Delete'),
            icon: 'delete',
            icon_color: 'text-red-500',
            command: () => {
              this.notificactionHelperService.setactionDeleteNotificationData(this.system_data);
            },
          },
        ],
      },
    ];
  }

  collapseChange() {
    this.collapsedStatus = !this.collapsedStatus;
    if (!this.system_data.read) {
      this.system_data.read = true;
      this.markRead(true);
    }
  }

  openSubPage(url: any) {
    this.router.navigate([url]);
  }

  openEntityProfile() {
    if (this.platform === 'core') {
      this.router.navigate([this.profile_link]);
    } else {
      window.open(`${this.environmentService.environment.coreUrl}` + this.profile_link, '_blank');
    }
  }

  /*markRead(state: boolean) {
    this.system_data.read = state;
    this.notificactionHelperService.setmarkReadNotificationData(this.system_data);
    this.renderMenu(this.system_data);
    this.notificactionHelperService.notificationUpdated.next(state);
  }*/
  markRead(state: boolean) {
    this.system_data.read = state;
    this.notificationsService
      .notificationsSetReadCreate({
        notification_id: this.system_data.id,
        is_read: state,
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {},
      });
    this.renderMenu(this.system_data);
    this.eventBus.emitEvent({ action: 'markRead' });
  }
}
