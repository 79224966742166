<div class="text-center text-sm leading-6 cursor-pointer" (click)="openPost()">
  {{
    'You have access to the email account and can send emails from this email address.' | translate
  }}

  <span>
    {{ 'Access this email address:' | translate }}
    <span class="text-primary">{{
      notification_data?.notification_profile_data?.email_address
    }}</span>
  </span>
</div>
