import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface Event {
  action: string;
  payload?: any;
}

@Injectable({
  providedIn: 'root',
})
export class EventBusService {
  private eventSubject = new BehaviorSubject<Event | null>(null);
  event$ = this.eventSubject.asObservable();

  emitEvent(event: Event) {
    this.eventSubject.next(event);
  }
}
