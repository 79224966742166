import { Component, inject, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { Router } from '@angular/router';

import { TruncatePipe } from '@offconon/shared/utils/pipes';
import { NotificationHelperService } from '@offconon/shared/utils/services';

@Component({
  selector: 'offconon-notification-type-activity-stream-reaction',
  templateUrl: './notification-type-activity-stream-reaction.component.html',
  styleUrls: ['./notification-type-activity-stream-reaction.component.scss'],
  imports: [TruncatePipe, MatIcon],
})
export class NotificationTypeActivityStreamReactionComponent {
  private router = inject(Router);

  @Input() notification_data: any;
  @Input() platform = 'core';

  private notificactionHelperService = inject(NotificationHelperService);

  open_link = '/';

  openPost() {
    if (this.platform === 'core') {
      this.open_link =
        '/activity-stream/show/' +
        this.notification_data?.notification_profile_data?.activity_stream_common_id;
    } else {
      this.open_link =
        '/activity-stream-posts/' +
        this.notification_data?.notification_profile_data?.activity_stream_common_id;
    }

    this.notificactionHelperService.closeNotificationPanel(false);

    this.router.navigate([this.open_link]);
  }
}
