<div>
  @if (system_data) {
    <p-panel [toggleable]="false" [collapsed]="collapsedStatus" [styleClass]="'w-full'">
      <ng-template #header>
        <div class="flex justify-between w-full">
          <div class="flex align-items-center" (click)="collapseChange()">
            <div class="mb-1 flex items-center justify-between cursor-pointer">
              <div class="flex items-center relative">
                @if (!avatar_path) {
                  <offconon-monogram
                    [avatarClass]="monogram_class"
                    [options]="{ size: '3.2rem' }"
                    [name]="full_name" />
                }
                @if (avatar_path) {
                  <p-avatar
                    size="large"
                    shape="circle"
                    styleClass="{{ monogram_class }}"
                    image="{{ avatar_path }}" />
                }

                <div class="ml-2 mt-1">
                  <span
                    class="flex items-center m-0 text-xs white-space-nowrap gap-1 {{
                      system_data.read ? 'font-medium' : 'font-extrabold'
                    }}">
                    {{ full_name | truncate: truncate_number }}

                    @if (is_verified) {
                      <span class="text-teal-500">
                        <mat-icon
                          style="height: initial; width: initial; font-size: 10px"
                          fontIcon="check_circle"
                          tooltipPosition="top"
                          [appendTo]="'body'"
                          [tooltipZIndex]="'99999'"
                          pTooltip=" {{ 'Verified' | translate }}" />
                      </span>
                    }
                    @if (is_subscription) {
                      <mat-icon
                        style="height: initial; width: initial; font-size: 10px"
                        class="cursor-pointer text-sm"
                        tooltipPosition="right"
                        [ngStyle]="{ color: subscription_color }"
                        fontIcon="{{ subscription_icon }}"
                        [appendTo]="'body'"
                        pTooltip="{{ subscription_name }} - {{ 'Premium member' | translate }}" />
                    }
                  </span>
                  <p
                    class="flex items-center gap-1 m-0 text-xs text-primary {{
                      system_data.read ? '' : 'font-bold'
                    }}">
                    <span>
                      {{
                        notificactionHelperService.actionTitleEnums[
                          system_data.notification_type_title
                        ] | truncate: truncate_number
                      }}
                    </span>
                    @if (!system_data?.read) {
                      <span class="flex text-blue-500">
                        <mat-icon
                          style="height: initial; width: initial; font-size: 13px"
                          tooltipPosition="right"
                          fontIcon="mark_unread_chat_alt"
                          pTooltip="{{ 'Unreaded' | translate }}" />
                      </span>
                    }
                  </p>

                  @if (system_data && system_data?.created_at) {
                    <span class="!text-xs m-0 {{ system_data.read ? '' : 'font-bold' }}">
                      <i class="pi pi-clock mr-1 !text-xs"></i>
                      <offconon-timeago [time_data]="system_data.created_at" />
                    </span>
                  }
                </div>
              </div>
            </div>
          </div>

          <!-- @if (!system_data?.read) {
          <span class="text-blue-500 mr-2 mt-3">
            <mat-icon
              style="height: initial; width: initial; font-size: 15px"
              pTooltip="{{ 'Unreaded' | translate }}"
              tooltipPosition="top"
              fontIcon="mark_unread_chat_alt" />
          </span>
        } -->
          <div>
            <button class="p-panel-header-icon p-link mr-2" (click)="menu.toggle($event)">
              <span class="pi pi-ellipsis-v"></span>
            </button>
            <button class="p-panel-header-icon p-link ml-2" (click)="collapseChange()">
              <span class="{{ collapsedStatus ? 'pi pi-plus' : 'pi pi-minus' }}"></span>
            </button>

            <p-menu #menu [appendTo]="'body'" [popup]="true" [model]="items">
              <ng-template #item let-item>
                @if (item?.visible !== false) {
                  <a class="flex align-items-center p-2 cursor-pointer mb-1 gap-2">
                    <span
                      class="inline-flex align-items-center justify-content-center text-4xl {{
                        item?.icon_color ? item?.icon_color : 'text-primary'
                      }}">
                      <mat-icon
                        class="{{ item?.icon_color ? item?.icon_color : 'text-primary' }} "
                        fontIcon="{{ item.icon ? item.icon : 'add_circle' }}" />
                    </span>
                    <span class="inline- flex flex-col gap-1">
                      <span class="font-light text-sm">{{ item.label }}</span>
                    </span>
                  </a>
                }
              </ng-template>
            </p-menu>
          </div>
        </div>
      </ng-template>
      @if (!collapsedStatus) {
        <div>
          <div class="font-light text-sm leading-6">
            {{ notificactionHelperService.actionTitleEnums[system_data.notification_type_title] }}
          </div>
          <div class="m-2">
            @switch (system_data.notification_type_title) {
              @case ('new_follower') {
                <offconon-notification-type-new-follower [notification_data]="system_data" />
              }

              @case ('activity_stream_reaction') {
                <offconon-notification-type-activity-stream-reaction
                  [notification_data]="system_data" />
              }

              @case ('email_added_to_co_worker') {
                <offconon-notification-type-add-employee-email-address
                  [notification_data]="system_data" />
              }

              @case ('sales_team_added_head') {
                <offconon-notification-type-sales-team [notification_data]="system_data" />
              }

              @case ('sales_team_removed_head') {
                <offconon-notification-type-sales-team [notification_data]="system_data" />
              }

              @case ('sales_team_added_member') {
                <offconon-notification-type-sales-team [notification_data]="system_data" />
              }

              @case ('sales_team_removed_member') {
                <offconon-notification-type-sales-team [notification_data]="system_data" />
              }

              @default {}
            }
          </div>
        </div>
      }
    </p-panel>
  }
</div>
