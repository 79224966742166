import { Component, inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { NotificationHelperService } from '@offconon/shared/utils/services';

@Component({
  selector: 'offconon-notification-type-sales-team',
  templateUrl: './notification-type-sales-team.component.html',
  imports: [TranslateModule],
})
export class NotificationTypeSalesTeamComponent {
  private router = inject(Router);

  @Input() notification_data: any;

  private notificactionHelperService = inject(NotificationHelperService);

  openPost() {
    this.notificactionHelperService.closeNotificationPanel(false);
    this.router.navigate([
      '/sales-team/sales-unit/' +
        this.notification_data?.notification_profile_data?.sales_org_chart_id,
    ]);
  }
}
