<div class="mb-1 flex flex-row cursor-pointer" (click)="openPost()">
  <div>
    <img
      class="emoji-icon-reaction"
      src="/assets/images/reaction/{{
        notification_data?.notification_profile_data?.reaction_type
      }}.gif"
      alt="{{ notification_data?.notification_profile_data?.reaction_type }}" />
    <div class="ml-1">
      <span
        class="block mt-1 text-blue-500 text-xs leading-6 white-space-nowrap {{
          notification_data.read ? '' : 'font-bold'
        }}">
        {{ notification_data?.notification_profile_data?.title || '' | truncate: 45 }}
        <mat-icon
          style="height: initial; width: initial; font-size: 10px"
          fontIcon="arrow_forward" />
      </span>
    </div>
  </div>
</div>
