import { Component, inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { PostInteractionRequest } from '@offconon/core-api';
import { FollowButtonCommonComponent } from '@offconon/shared/ui/follow-buttons';
import { NotificationHelperService } from '@offconon/shared/utils/services';

import { TruncatePipe } from '../../../../../../../utils/pipes/src/lib/truncate.pipe';

@Component({
  selector: 'offconon-notification-type-new-follower',
  templateUrl: './notification-type-new-follower.component.html',
  imports: [TranslateModule, TruncatePipe, FollowButtonCommonComponent],
})
export class NotificationTypeNewFollowerComponent implements OnInit {
  service_type: any;
  service_type_business: any;
  constructor() {
    this.service_type = PostInteractionRequest.ServiceTypeEnum.User;
    this.service_type_business = PostInteractionRequest.ServiceTypeEnum.Business;
  }
  @Input() notification_data: any;
  @Input() platform = 'core';
  @Input() truncate_number = 35;
  @Input() parameters: any;

  private router = inject(Router);
  public notificactionHelperService = inject(NotificationHelperService);
  private url: any = '';
  public name: string = '';
  public action: string = '';

  ngOnInit() {
    if (this.notification_data.sender_type === 'user') {
      this.url = '/profile/' + this.notification_data.sender_user.username;
      this.action =
        this.notificactionHelperService.actionTitleEnums[
          this.notification_data.notification_type_title
        ];
      this.name = this.notification_data.sender_user.full_name;
    }
    if (this.notification_data.sender_type === 'business') {
      this.url =
        '/business-profile/' +
        this.notification_data.sender_business.business_mention_name.toLowerCase();
      this.action =
        this.notificactionHelperService.actionTitleEnums[
          this.notification_data.notification_type_title
        ];
      this.name = this.notification_data.sender_business.business_name;
    }
  }
  loadProfile() {
    this.router.navigate([this.url]);
  }
}
