<p>{{ 'Check your popularity' | translate }}</p>
<div class="flex justify-between">
  <p class="cursor-pointer text-sm" (click)="loadProfile()">
    <span class="text-blue-500 text-xs">{{ action | truncate: truncate_number }}</span
    >: {{ name }}
  </p>
  @if (notification_data.sender_type === 'user') {
    <offconon-follow-button-common
      [small_css]="true"
      [service_id]="notification_data?.sender_user?.user_id"
      [service_type]="service_type"
      [isFollowType]="notification_data?.sender_user?.is_follow" />
  } @else if (notification_data.sender_type === 'business') {
    <offconon-follow-button-common
      [small_css]="true"
      [service_id]="notification_data?.sender_business?.business_id"
      [service_type]="service_type_business"
      [isFollowType]="notification_data?.sender_business?.is_follow" />
  }
</div>
